<template>
    <router-link class="card-link" v-if="!showMore" :to="linkTo" :title="linkToTitle" :alt="linkToTitle" :target="openNewTab">
        <div :class="['card', 'ort-hover', autoWidth ? 'card-block': '',noslider ? 'no-slider': '',typeClass, isHighlighted, address!=''?'has-address':'no-address']" @click="highlight">
            <div class="card-head">
                <div class="card-img">
                    <div v-if="backgroundImg!==null" :style="backgroundImg" class="bg-img"></div>
                    <div v-else="" :alt="subCategory" class="card-img__icon"><img :src="icon"></div>
                </div>
                <div class="card-content">
                    <span :class="['category', 'h5']" v-if="category!==''">{{ category }}:<br></span>
                    <span :class="['category', 'h5']">{{ subCategory }}</span>
                    <h3 class="title">{{ title }}</h3>
                </div>
            </div>
            <div class="card-content">
                <ul class="card-content__list">
                    <li v-if="address !== ''">
                        <div class="icon"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">place</span></div>
                        <div class="info do-not-translate" data-wg-notranslate="">
                            <span v-html="address"></span>
                        </div>
                    </li>
                </ul>
                <router-link v-if="showMore" :to="linkTo" :title="linkToTitle" :target="openNewTab" class="btn btn-round more-info">Daha fazla bilgi       
                    <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
                </router-link>
                <bookmark-button aria-hidden="true" tabindex="-1" :parentClass="'card-body-icon'" :content="this.item" v-if="this.item!==null"></bookmark-button>
                <card-share-button aria-hidden="true" tabindex="-1" class="float-right" :link="linkTo"></card-share-button>
            </div>

        </div>
    </router-link>

    <div v-else="" :class="['card', autoWidth ? 'card-block': '',noslider ? 'no-slider': '',typeClass, isHighlighted, address!=''?'has-address':'no-address']" @click="highlight" :title="linkToTitle" :alt="linkToTitle">
        <div class="card-head">
            <div class="card-img">
                <div v-if="logo==''" :alt="subCategory" class="card-img__icon"><img :src="icon"></div>
                <div v-else="" :style="backgroundImg" class="bg-img" role="img" aria-label="Logo"></div>
            </div>
            <div class="card-content">
                <span :class="['category', 'h5']" v-if="category!==''">{{ category }}:<br></span>
                <span :class="['category', 'h5']">{{ subCategory }}</span>
                <h3 class="title">{{ title }}</h3>
            </div>
        </div>
        <div class="card-content">
            <ul class="card-content__list">
                <li v-if="address !== ''">
                    <div class="icon"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">place</span></div>
                    <div class="info do-not-translate" data-wg-notranslate="">
                        <span v-html="address"></span>
                    </div>
                </li>
            </ul>
            <router-link v-if="showMore" :to="linkTo" :title="linkToTitle" :alt="linkToTitle" :target="openNewTab" class="btn btn-round more-info">Daha fazla bilgi         
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </router-link>
            <card-share-button aria-hidden="true" tabindex="-1" class="float-right" :link="linkTo"></card-share-button>
            <bookmark-button aria-hidden="true" tabindex="-1" :parentClass="'card-body-icon'" :content="this.item" v-if="this.item!==null"></bookmark-button>
        </div>

    </div>
</template>

<script>
import { getCategoryIcon, getSubCategory } from '@/utils/lupe-helpers';
import { getFieldValues, getFirstFieldValue, json_decode } from '@/utils/helpers';

export default {
    name: 'OrteCard',
    components: {
        CardShareButton: () => import('@/components/controls/CardShareButton.vue'),
        BookmarkButton: () => import('@/components/controls/BookmarkButton'),
    },
    props: {
        showMore: {
            type: Boolean,
        default: false
        },
        item: {
            type: Object,
            required: true
        },
        autoWidth: {
            type: Boolean,
        default: false,
        },
        noslider: {
            type: Boolean,
        default: false,
        },
        target: {
            type: String,
        default: "list",
        },
        openInNewTab: {
            type: Boolean,
        default: false,
        }
    },
    computed: {
        openNewTab() {
            if (this.openInNewTab) {
                return "_blank";
            }
            return "_self";
        },
        title() {
            var maxTextLength = 55;
            if (this.$isMobileScreen) {
                maxTextLength = 30;
            }

            var title = this.item.title.length > maxTextLength ? this.item.title.substring(0, maxTextLength) + ' ...' : this.item.title;
            return title;
        },
        openNewTab() {
            if (this.openInNewTab) {
                return "_blank";
            }
            return "_self";
        },
        linkTo() {
            return "/orte/" + this.item.institution_id;
        },
        linkToTitle() {
            return "Mehr Informationen zu: " + this.item.title;
        },
        category() {
            var value = getFirstFieldValue(this.item, 'kielrefugeecompass');
            return value != null ? value : '';
        },
        subCategory() {
            return getSubCategory(this.item, this.category);
        },
        isHighlighted() {
            if (this.item.hasOwnProperty("highlight") && this.item.highlight == true) {
                return "highlighted";
            }
            return "not-highlighted"
        },
        backgroundImg() {
            if (typeof this.logo !== 'undefined' && this.logo !== null && this.logo !== '') {
                return 'backgroundImage: url("' + this.logo + '")';
            }
            return null;
        },
        categoryClass() {
            switch (this.category) {
            case 'Bildung':
            case 'Eğitim':
            case 'Education':
            case 'تعليم':
            case 'Освіта':
                return 'Bildung';
                break;
            case 'Freizeit':
            case 'Boş Zaman':
            case 'Leisure':
            case 'وقت الفراغ':
            case 'Вільний час':
                return 'Freizeit';
                break;
            case 'Ehrenamt & Engagement':
            case 'Gönüllülük ve Bağlılık':
            case 'Volunteering & Commitment':
            case 'التطوع والالتزام':
            case 'Волонтерство та відданість':
                return 'Ehrenamt & Engagement';
                break;
            case 'Beratung':
            case 'Tavsiye':
            case 'Counselling':
            case 'نصيحة':
            case 'Порада':
                return 'Beratung';
                break;
            case 'Sprache':
            case 'Dil':
            case 'Language':
            case 'لغة':
            case 'Мова':
                return 'Sprache';
                break;
            case 'Wohnen':
            case 'İkamet':
            case 'Housing':
            case 'مسكن':
            case 'Проживати':
                return 'Wohnen';
                break;
            default:
                return '';
            }
        },
        icon() {
            switch (this.categoryClass) {
            case 'Bildung':
                return '/assets/icons/kategorien/bildung.png';
                break;
            case 'Freizeit':
                return '/assets/icons/kategorien/freizeit.png';
                break;
            case 'Ehrenamt & Engagement':
                return '/assets/icons/kategorien/ehrenamt.png';
                break;
            case 'Beratung':
                return '/assets/icons/kategorien/beratung.png';
                break;
            case 'Sprache':
                return '/assets/icons/kategorien/sprache.png';
                break;
            case 'Wohnen':
                return '/assets/icons/kategorien/wohnen.png';
                break;
            default:
                return '/assets/RefugeeCompass_bg.png';
            }
        },
        typeClass() {
            if (this.categoryClass !== null && this.categoryClass !== '') {
                return this.categoryClass !== null ? this.categoryClass.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, '') : '';
            } else {
                return 'no-cat';
            }
        },
        logo() {
            if (this.item !== null) {
                var logo = getFirstFieldValue(this.item, 'logo');
                if (logo !== null) {
                    logo = json_decode(logo);
                    if (typeof logo === "object") {
                        return this.$backendUrl + logo.path;
                    } else {
                        logo = logo.charAt(0) != "/" ? "/" + logo : logo;
                        return this.$backendUrl + logo;
                    }
                }
            }
            return "";
        },
        address() {
            var address = getFieldValues(this.item, 'adresse');
            if (address !== null) {
                var gna = "";
                if (address.hasOwnProperty('street') && address.street !== null) {
                    gna = gna + address.street + '<br>';
                }
                if (address.hasOwnProperty('zipcode') && address.zipcode !== null) {
                    gna = gna + address.zipcode + ', ';
                }
                if (address.hasOwnProperty('city') && address.city !== null) {
                    gna = gna + address.city;
                }

                if (gna !== "") {
                    gna = gna.length > 40 ? gna.substring(0, 40) + ' ...' : gna;
                }

                return gna;
            }
            return "";
        }
    },
    methods: {
        json_decode,
        getFieldValues,
        getFirstFieldValue,
        highlight(event) {
            if (this.target == "map") {
                this.$emit('highlight', {sender: 'card', content: this.item});
            }
        }
    }
}
</script>

<style lang="scss" scoped="">
@import '@/scss/_variables.scss';

.card {
    display: flex;
    position: relative;
    width: 100%;
    border: none;
    border-radius: 0px;
    margin-right: 15px;
    overflow: visible;
    box-shadow: 1px 2px 10px 1px #00000040;
    border-radius: 0px 0px 0px 40px;
    min-height: 300px;
    margin-bottom: 20px;

    &-head {
        overflow: hidden;
    }

    &.has-address{
        cursor: pointer;
    }

    .card-img__icon{
        margin: 14px;
        fill: $primary;
    }
    &.no-cat {
        .card-head {
            background-color: #E8F6FC;
        }

        &.highlighted{
            border: 3px solid #bdd0d9;
        }
        .card-img{
            border: 3px solid #bdd0d9;
        }
        .card-img__icon {
            min-height: auto;
            min-width: auto;

            img {
                max-width: 60px;
                max-height: 60px;
            }
        }
    }

    &.Freizeit{
        .card-head {
            background-color: #C7E4FF;
        }
        &.highlighted{
            border: 3px solid #90C9FF;
        }

        .card-img{
            border: 3px solid #90C9FF;
        }

        .card-img__icon{
            fill: $primary;
        }
    }

    &.Beratung{
        .card-head {
            background-color: #FCCC9F;
        }
        &.highlighted{
            border: 3px solid #FA993F;
        }

        .card-img{
            border: 3px solid #FA993F;
        }

        .card-img__icon{
            fill: $wb-meganta;
        }
    }

    &.Bildung{
        .card-head {
            background-color: #FDEBB6;
        }
        &.highlighted{
            border: 3px solid #FCD770;
        }

        .card-img{
            border: 3px solid #FCD770;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &.Wohnen{
        .card-head {
            background-color: #DED5F3;
        }
        &.highlighted{
            border: 3px solid #DED5F3;
        }

        .card-img{
            border: 3px solid #DED5F3;
        }

        .card-img__icon{
            fill: $primary;
        }
    }

    &.Sprache{
        .card-head {
            background-color: #F6A7AB;
        }
        &.highlighted{
            border: 3px solid #ED4F58;
        }

        .card-img{
            border: 3px solid #ED4F58;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &.EhrenamtEngagement{
        .card-head {
            background-color: #A4E6C7;
        }
        &.highlighted, &:focus-visible{
            border: 3px solid #46CC8D;
        }

        .card-img{
            border: 3px solid #46CC8D;
        }

        .card-img__icon{
            fill: $wb-jade;
        }
    }

    &-img {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        background-color: $white-color;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 17px;
        margin-top: 31px;

        img {
            max-width: 60px;
            max-height: 50px;
        }
    }

    &-content {
        padding: 20px;

        .category {
            color: #3A3A3A;
            margin-bottom: 20px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;

        }

        .time {
            color: $text-light;
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            margin-top: 6px;
            margin-bottom: 12px;
        }

        .image {
            img {
                max-height: 80px;
            }
        }

        .desc {
            height: 68px;
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 10px;
        }

        &__list {
            display: block;
            list-style: none;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 5px;
                color: $black-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    span {
                        font-size: 18px;
                        margin-right: 15px;
                        line-height: 24px;
                    }
                }

                .info {
                    display: inline-flex;
                    flex-direction: column;
                }
            }
        }
    }

    &:first-child {
        margin-left: 15px;

        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }

    &-block {
        min-width: 290px;
        max-width: 100%;
        width: 100%;
        margin-right: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    &.no-slider {
        margin-right: 0;
        margin-left: 15px;
    }
}

.card-head {
    display: flex;
    height: 140px;
}

.more-info {
    position: absolute;
    bottom: 0;
    margin-bottom: 7px;
    display: flex;
    height: 41px;
    align-items: center;
    text-transform: none;

    &:hover {
        color: #FFF;
        background-color: #2e55a5;
    }
}

.bg-img {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    border: 3px solid #FFF;
    background-color: #FFF;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.card-link{
    &:hover {
        text-decoration: none;
    }
    &:focus-visible {
        outline-style: dashed !important;
        outline-color: #21b5ea !important;
        outline-width: 2px !important;
        border: none !important;
        text-decoration: none !important;
    }
}

.ort-hover:hover {
    box-shadow: 1px 2px 12px 3px rgba(0, 0, 0, 0.3);
}



body[dir="rtl"] {
    .card{
        margin-right: 0;
        margin-left: 15px;

        .card-img {
            left: auto;
            margin-right: 17px;
            margin-left: 0;
        }

        .card-content__list {
            li {
                .icon {
                    span {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
            }
        }

        &:first-child {
            margin-right: 15px;
        }

        &.card-block {
            margin-left: 0;

        }
    }
}
</style>
